import styled from "styled-components";

export const CustomButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  height: 35px;
  border: none;
  outline: none;
  background: var(--clr-primary-900);
  box-shadow: 0px 4px 20px 0px rgba(90, 2, 14, 0.15);
  padding: 10px 20px;
  color: var(--clr-white-100);
  border-radius: 3px;
  text-transform: Capitalize;
  cursor: pointer;
  gap: 10px;
  svg {
    font-size: 18px;
  }
  transition: all 0.25s ease-in-out;
  box-shadow: var(--box-shadow-2);
  border-radius: 50px;

  &:hover {
    background: var(--clr-primary-100);
    box-shadow: var(--box-shadow);
  }
`;
