export const TabList = [
  {
    id: 1,
    name: "Profile",
    link: "/account",
  },
  {
    id: 2,
    name: "Security",
    link: "/change-password",
  },
];
