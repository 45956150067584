import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { MdFamilyRestroom } from "react-icons/md";

export interface AdmissionState {
  printingAdmissionAcknowledgementSlip: boolean;
  acknowledgementSlip: any;
  printingAdmissionLetter: any;
  admissionLetter: any;
}

const initialState: AdmissionState = {
  printingAdmissionAcknowledgementSlip: false,
  acknowledgementSlip: null,
  printingAdmissionLetter: MdFamilyRestroom,
  admissionLetter: null,
};

export const AdmissionSlice = createSlice({
  name: "Admission",
  initialState,
  reducers: {
    setPrintingAcknowledgementSlip: (state, action) => {
      state.printingAdmissionAcknowledgementSlip = action.payload;
    },
    setACknowledgementSlip: (state, action) => {
      state.acknowledgementSlip = action.payload;
    },
    setPrintingAdmissionLetter: (state, action) => {
      state.printingAdmissionLetter = action.payload;
    },
    setAdmissionLetter: (state, action) => {
      state.admissionLetter = action.payload;
    },
    getAdmissionsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setACknowledgementSlip,
  setPrintingAcknowledgementSlip,
  getAdmissionsFormDetails,
  setAdmissionLetter,
  setPrintingAdmissionLetter,
} = AdmissionSlice.actions;
export const selectAdmission = (state: RootState) => state.admission;

export default AdmissionSlice.reducer;
