import React from 'react'
import { ItemsCenterJustifyBtw, PageTitle } from '../style'
import PaymentHistoryFilter from './PaymentHistoryFilter'
import AllPaymentsTable from '../../../Tables/AllPayments'
import UnSuccessfulPayment from '../../../Tables/AllPayments/UnSuccessfulPayments'
import { useEffect } from 'react';
import { getPaymentHistory } from '../../../Apis/Private/Payment'
import { useAppSelector } from '../../../Redux/app/hooks'
import { selectPayment } from '../../../Redux/features/paymentSlice'

const PaymentHistory = () => {
    const {sessionId} = useAppSelector(selectPayment)

useEffect(() => {
    sessionId && getPaymentHistory(sessionId)
},[sessionId])

  return (
    <div>
        <ItemsCenterJustifyBtw>
        <PageTitle>Payment History</PageTitle>
        <PaymentHistoryFilter />
        </ItemsCenterJustifyBtw>
        <AllPaymentsTable />
        <UnSuccessfulPayment />
    </div>
  )
}

export default PaymentHistory