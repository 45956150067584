import { InnerNavContainer, Tab } from "./styles";
import { useLocation } from "react-router-dom";

interface InnerNavProps {
  tabList?: any;
}
const InnerNavTabs = ({ tabList }: InnerNavProps) => {
  const { pathname } = useLocation();

  return (
    <InnerNavContainer>
      {tabList?.map((tab: any) => (
        <Tab
          to={tab?.link}
          className={`${pathname === tab?.link && "active"}`}
          key={tab?.id}
        >
          {tab?.name}
        </Tab>
      ))}
    </InnerNavContainer>
  );
};

export default InnerNavTabs;
