import React, { FC } from "react";
import { styled } from "styled-components";
import { media } from "../../Screens";

interface StyleProp {
  flex?: string;
}

const Container = styled.div<StyleProp>`
  display: flex;
  width: 100%;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--clr-white-100);
  background: rgba(255, 255, 255, 0.3);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  padding: 10px;
  gap: 20px;

  ${media.tablet} {
    padding: 30px;
    flex-direction: row;
  }

  &:first-child {
    // flex: ${(props) => props.flex};
  }
`;

interface CardProps {
  children: any;
  flex?: string;
}

const LongTransparentCard: FC<CardProps> = ({ children, flex }) => {
  return <Container flex={flex}>{children}</Container>;
};

export default LongTransparentCard;
