import React, { FC, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import SmallButton from "../Components/Form/Buttons/SmallButton";
import { FaPrint } from "react-icons/fa";
import styled from "styled-components";
import { media } from "../Screens";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { ItemsCenterJustifyBtw } from "./PrivatePages/style";
import LongTransparentCard from "../Components/Layouts/LongTransparentCard";
import LongWhiteCard from "../Components/Layouts/LongWhiteCard";
import TextCenteredInput from "../Components/Form/Inputs/TextCenteredInput";
import { FormContainer } from "./AuthPages/styles";
import { useState } from "react";
import { CustomButton } from "../Components/Form/Buttons/styles";

interface ReactPrintProp {
  children: any;
  pay?: boolean;
  etransactPaymentLink?: string;
  remitaObject?: any;
}

const Printer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${media.tablet} {
    width: 70%;
  }
`;

const ReactPrint: FC<ReactPrintProp> = ({
  children,
  pay,
  etransactPaymentLink,
  remitaObject,
}) => {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    removeAfterPrint: true,
  });

  return (
    <Printer>
      <div ref={contentToPrint}>{children}</div>
      <ItemsCenterJustifyBtw className="justify-around">
        <SmallButton
          buttonText="Print"
          leftIcon={<FaPrint />}
          handleClick={() => {
            handlePrint(null, () => contentToPrint.current);
          }}
        />
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {pay && etransactPaymentLink && (
            <SmallButton
              buttonText="Etranzact Credo"
              leftIcon={<RiMoneyDollarCircleLine />}
              handleClick={() => {
                window.open(`${etransactPaymentLink}`, "_blank");
              }}
            />
          )}
          {remitaObject && (
            <form
              action="https://login.remita.net/remita/ecomm/finalize.reg"
              method="POST"
            >
              <TextCenteredInput
                alignText="left"
                value={remitaObject?.response?.merchantCode}
                name="merchantId"
                type="hidden"
              />
              <TextCenteredInput
                alignText="left"
                placeholder="Hash"
                value={remitaObject?.hash}
                name="hash"
                type="hidden"
              />
              <TextCenteredInput
                alignText="left"
                placeholder="RRR"
                value={remitaObject?.response?.rrr}
                name="rrr"
                type="hidden"
              />
              <TextCenteredInput
                alignText="left"
                value={remitaObject?.responseurl}
                type="hidden"
                name="responseurl"
              />
              <CustomButton type="submit">Pay Via Remita</CustomButton>
            </form>
          )}
        </div>
      </ItemsCenterJustifyBtw>
    </Printer>
  );
};

export default ReactPrint;
