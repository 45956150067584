import { toast } from "react-toastify";
import api from "../baseUri";
import {
  setGeneratedInvoice,
  setGeneratingInvoice,
  setGettingPaymentHistory,
  setGettingPaymentReceipt,
  setPaymentHistory,
  setPaymentReceipt,
  setUnSuccessfulPayments,
} from "../../Redux/features/paymentSlice";
import { store } from "../../Redux/app/store";

export const getPaymentHistory = async (sessionId: number | null) => {
  store.dispatch(setGettingPaymentHistory(true));
  const params = { sessionId };
  await api
    .get("Student/PaymentHistory", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setGettingPaymentHistory(false));
      store.dispatch(setPaymentHistory(data?.successfulPayments));
      store.dispatch(setUnSuccessfulPayments(data?.unSuccessfulPayments));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentHistory(false));
    });
};


export const generateInvoice = async (feescheduleId:number | null, sessionId:number| null,semesterId:number | null ) => {
  store.dispatch(setGeneratingInvoice(true));
  const params = { sessionId,feescheduleId,semesterId };
  await api
    .get("Payments/Student/GenerateInvoice", { params })
    .then((res:any) => {
      const {data} = res
      store.dispatch(setGeneratedInvoice(data))
      store.dispatch(setGeneratingInvoice(false));
      toast.success("Invoice generated Successfully")
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGeneratingInvoice(false));
    });
};

export const getPaymentReceipt = async (paymentId: number) => {
  store.dispatch(setGettingPaymentReceipt(true));
  const params = {
    paymentId
  }
  await api
    .get(`/Student/PaymentReciept`, {params})
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentReceipt(data));
      store.dispatch(setGettingPaymentReceipt(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentReceipt(false));
    });
};