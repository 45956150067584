import { FC } from "react";
import styled from "styled-components";

interface InputProps {
  alignText?: string;
}
const Input = styled.input<InputProps>`
  align-self: stretch;
  background: var(--clr-white-100);
  outline: none;
  border: none;
  border-bottom: 1px solid var(--clr-grey-100);
  padding: 10px 0;
  text-align: ${(props) => props.alignText || `center`};
  transition: all 0.5s ease;
  width: 100%;
  min-width: 200px;

  &:focus {
    border-color: var(--clr-black);
    color: var(--clr-black);
  }
`;

interface TextInputProps {
  placeholder?: string;
  alignText?: string;
  type?: string;
  max?: string;
  defaultValue?: any;
  value?: any;
  name?: string;
  onChangeHandler?: any;
}
const TextCenteredInput: FC<TextInputProps> = ({
  placeholder,
  alignText,
  type,
  max,
  defaultValue,
  value,
  name,
  onChangeHandler,
}) => {
  return (
    <Input
      placeholder={placeholder}
      defaultValue={defaultValue}
      alignText={alignText}
      type={type}
      max={max}
      value={value}
      name={name}
      onChange={onChangeHandler}
    />
  );
};

export default TextCenteredInput;
