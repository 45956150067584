import { toast } from "react-toastify";
import { store } from "../../Redux/app/store";
import {
  setLoginUser,
  setResettingPassword,
  setUser,
} from "../../Redux/features/userSlice";
import api from "../baseUri";

export const loginUser = async (username: string, password: string) => {
  store.dispatch(setLoginUser(true));
  const body = {
    username,
    password,
  };
  await api
    .post("/Account/Login", body)
    .then((res) => {
      if (res.data.response?.roleId === (10)) {
        store.dispatch(setUser(res.data.response));
      } else {
        toast.error("This role cannot login to the app");
      }
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setLoginUser(false));
    });
};

export const resetPassword = async (
  username: string,
  password: string,
  newPassword: string
) => {
  store.dispatch(setResettingPassword(true));
  const body = {
    username,
    password,
    newPassword,
  };
  await api
    .post("Account/ForgetPassword", body)
    .then((res) => {
      store.dispatch(setResettingPassword(false));
      toast.success(`Password reset Successful`);
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setResettingPassword(false));
    });
};
