import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../Redux/app/hooks';
import { selectUtil } from '../../../Redux/features/utilsSlice';
import { filter } from '../util';
import UnderlinedSelector from '../../../Components/Form/Dropdowns/UnderlinedSelector';
import { getClearancesFormDetails, selectClearance } from '../../../Redux/features/clearanceSlice';
import { PageTitle } from '../style';
import SmallButton from '../../../Components/Form/Buttons/SmallButton';
import { postClearanceForm } from '../../../Apis/Private/clearance';
import { toast } from 'react-toastify';
import ClearanceForm from './ClearanceForm';


const Clearance = () => {
  const { sessions, semesters } =
  useAppSelector(selectUtil);
  const { sessionId, semesterId,postingClearanceForm, clearanceForm } =
  useAppSelector(selectClearance);
  const dispatch = useAppDispatch()

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getClearancesFormDetails({ name, value }));
  };

  const handleCreateForm = (e:any) =>{
    e.preventDefault()
    if(sessionId && semesterId){
      postClearanceForm(sessionId, semesterId)
    }
    else{
      toast.error("Please enter semester and session")
    }
  }


  return (
    <>
    <PageTitle>Get Clearance Form</PageTitle>
    <div className="flex-centered-items flex-wrap">
      <UnderlinedSelector
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="sessionId"
        value={sessionId}
      />
      <UnderlinedSelector
        dropdownItems={semesterFilter}
        title={`Semester`}
        handleDropdownSelect={handleSelect}
        name="semesterId"
        value={semesterId}
      /> 
    </div>
    <SmallButton buttonText='Get Form' handleClick={handleCreateForm} loading={postingClearanceForm}/>
  {clearanceForm && 
      <ClearanceForm />
    }
    </>
    
  )
}

export default Clearance

