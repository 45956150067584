import { PiCaretUpDownFill } from "react-icons/pi";
import { Dropdown, Popover, Whisper, Button } from "rsuite";
import styled from "styled-components";

const ButtonContainer = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  background: var(--clr-white-100);
  outline: none;
  border: none;
  border-bottom: 1px solid var(--clr-grey-100);
  padding:10px;
  text-align: left;
  transition: all 0.5s ease;
  width: 100%;

  &:focus {
    border-color: var(--clr-black);
    color: var(--clr-black);
  }

  &:hover {
    background: none;
  }
`;

const PopoverContainer = styled(Popover)`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  border: 1px solid var(--clr-white-100);
  backdrop-filter: blur(2px);
  min-width: 100px;
  max-width: 200px;
  max-height: 300px;
  overflow-y: auto;

  .rs-dropdown-item {
    color: var(--clr-black);
    font-size: 12px;
    font-style: normal;
    font-weight: var(--fw-bold);
    line-height: 16px;
  }
`;

const renderSpeaker = (
  { onClose, left, top, className,open, ...rest }: any,
  ref: any,
  dropdownItems: string[],
  handleDropdownSelect: any,
  name?: string
) => {
  const handleSelect = (e: any) => {
    handleDropdownSelect(name, e?.value);
    // this.close()
  };
  return (
    <PopoverContainer
      ref={ref}
      className={className}
      style={{ left, top }}
      full
    >
      <Dropdown.Menu onSelect={handleSelect}>
        {dropdownItems?.map((item: any, index: any) => (
          <Dropdown.Item eventKey={item} value={item?.value}>
            {item?.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </PopoverContainer>
  );
};

interface DropdownProps {
  dropdownItems?: any;
  title?: any;
  handleDropdownSelect?: any;
  name?: string;
  value?: any;
}

const UnderlinedSelector = ({
  dropdownItems,
  title,
  handleDropdownSelect,
  name,
  value,
}: DropdownProps) => {
  return (
    <Whisper
      placement="bottomStart"
      trigger="click"
      speaker={renderSpeaker(``, ``, dropdownItems, handleDropdownSelect, name)}
    >
      <ButtonContainer>
        <span>
          {dropdownItems?.filter((x: any) => x?.value === value)[0]?.label ===
            "All" || null
            ? title
            : dropdownItems?.filter((x: any) => x?.value === value)[0]?.label}
        </span>
        <PiCaretUpDownFill />
      </ButtonContainer>
    </Whisper>
  );
};

export default UnderlinedSelector;
