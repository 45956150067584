import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../Redux/app/hooks'
import { getHostelsFormDetails, selectHostel } from '../../../Redux/features/hostelSlice'
import { filter } from '../util'
import { useEffect } from 'react';
import { getActiveBlockRoomByBlockId, getActiveBunkByRoomId, getHostelActiveBlockByHostelId, getHostelsToChoose, saveHostelSelection } from '../../../Apis/Private/hostel';
import { selectUtil } from '../../../Redux/features/utilsSlice';
import { toast } from 'react-toastify';
import UnderlinedSelector from './../../../Components/Form/Dropdowns/UnderlinedSelector';
import SmallButton from '../../../Components/Form/Buttons/SmallButton';
import { PageTitle } from '../style';
import { ItemsCenterJustifyBtw } from './../style';
import ReactPrint from '../../ReactPrint';
import { ReceiptBody, ReceiptContainer, ReceiptHeader, SchoolName, SchoolPhone } from '../Payments/styles';
import LongTransparentCard from '../../../Components/Layouts/LongTransparentCard';
import LongWhiteCard from '../../../Components/Layouts/LongWhiteCard';

const Hostel = () => {
  const {chosenHostel,allocatedHostel, hostelActiveBlockByHostelId, savingHostelSelection,activeBlockRoomByBlockId, activeBunkByRoomId, semesterId, hostelId, blockId, roomId, bunkId} = useAppSelector(selectHostel)
  const {semesters} = useAppSelector(selectUtil)
  const dispatch = useAppDispatch()

  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const hostelsFilter = filter(chosenHostel, "hostelName", "hostelId");
  const hostelsBlockFilter = filter(hostelActiveBlockByHostelId, "blockName", "blockId");
  const hostelsBlockRoomFilter = filter(activeBlockRoomByBlockId, "text", "value");
  const hostelsBlockRoomBunkFilter = filter(activeBunkByRoomId, "text", "value");

  useEffect(() => {
   semesterId &&  getHostelsToChoose(semesterId)
  },[semesterId])

  useEffect(() => {
   hostelId &&  getHostelActiveBlockByHostelId(hostelId)
  },[hostelId])

  useEffect(() => {
    blockId && getActiveBlockRoomByBlockId(blockId)
  },[blockId])

  useEffect(() => {
    roomId && getActiveBunkByRoomId(roomId)
  },[roomId])

  const handleHostelSave = (e:any) => {
    e.preventDefault()
    if(semesterId && roomId){
        saveHostelSelection(semesterId,bunkId )
    }
    else{
        toast.success("Semester or bunk value is missing. Please fill the fields")
    }
  }

  
  const handleSelect = (name: string, value: string | number) => {
    dispatch(getHostelsFormDetails({ name, value }));
  };



  return (
    <>
    <PageTitle>Hostel Booking</PageTitle>
    <ItemsCenterJustifyBtw>
        <UnderlinedSelector
        dropdownItems={semesterFilter}
        title={`Semester`}
        handleDropdownSelect={handleSelect}
        name="semesterId"
        value={semesterId}
        />
        <UnderlinedSelector
        dropdownItems={hostelsFilter}
        title={`Hostels`}
        handleDropdownSelect={handleSelect}
        name="hostelId"
        value={hostelId}
        />
        <UnderlinedSelector
        dropdownItems={hostelsBlockFilter}
        title={`Block`}
        handleDropdownSelect={handleSelect}
        name="blockId"
        value={blockId}
        />
        <UnderlinedSelector
        dropdownItems={hostelsBlockRoomFilter}
        title={`Room`}
        handleDropdownSelect={handleSelect}
        name="roomId"
        value={roomId}
        />
        <UnderlinedSelector
        dropdownItems={hostelsBlockRoomBunkFilter}
        title={`Bunk`}
        handleDropdownSelect={handleSelect}
        name="bunkId"
        value={bunkId}
        />
         
    </ItemsCenterJustifyBtw>
    <SmallButton
        buttonText="Save"
    //  leftIcon={<IoMdAdd />}
        loading={savingHostelSelection}
        handleClick={handleHostelSave}
        disabled={allocatedHostel?.matricNo}
    /> 
    {allocatedHostel &&  
    <LongTransparentCard>
    <LongWhiteCard>
        <ReactPrint pay={false} >
          <ReceiptContainer>
          <ReceiptHeader>
            <img src="/assets/images/PNG/logo.png" alt="logo"/>
            <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
            <SchoolPhone>Allocated Hostel Details</SchoolPhone>
          </ReceiptHeader>
          <ReceiptBody>
            <ItemsCenterJustifyBtw>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Name:</span>
                <span>
                  {allocatedHostel?.name} 
                </span>
              </div>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Matric. NO.:</span>
                <span>
                  {allocatedHostel?.matricNo} 
                </span>
              </div>
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Department:</span>
                <span>
                  {allocatedHostel?.department} 
                </span>
              </div>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Level:</span>
                <span>
                  {allocatedHostel?.level} 
                </span>
              </div>
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Session:</span>
                <span>
                  {allocatedHostel?.session} 
                </span>
              </div>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Semester:</span>
                <span>
                  {allocatedHostel?.semester} 
                </span>
              </div>
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Hostel:</span>
                <span>
                  {allocatedHostel?.hostel} 
                </span>
              </div>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Block:</span>
                <span>
                  {allocatedHostel?.hostelBlock} 
                </span>
              </div>
            </ItemsCenterJustifyBtw>
            <ItemsCenterJustifyBtw>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Room:</span>
                <span>
                  {allocatedHostel?.roomNo} 
                </span>
              </div>
            <div className="flex-centered-items" style={{ fontSize: `16px` }}>
                <span style={{ color: `var(--clr-black)` }}>Bunk:</span>
                <span>
                  {allocatedHostel?.bunk} 
                </span>
              </div>
            </ItemsCenterJustifyBtw>
            </ReceiptBody>
          </ReceiptContainer>
        </ReactPrint>
      </LongWhiteCard>
      </LongTransparentCard>
    }
    </>
)}

export default Hostel