import { toast } from "react-toastify";
import api from "../baseUri";
import { store } from "../../Redux/app/store";
import { setCheckingResult } from "../../Redux/features/resultSlice";

export const checkResult = async (sessionId: number | null) => {
  store.dispatch(setCheckingResult(true));
  const params = { sessionId };
  await api
    .get("Student", { params })
    .then((res) => {
      const {data} = res
      store.dispatch(setCheckingResult(false));
      window.open(`${data}`, '_blank')
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setCheckingResult(false));
    });
};
