import React from "react";
import AuthPageLayout from "../../../Components/Layouts/AuthPageLayout";
import { AuthRedirectionLink, FormContainer } from "../styles";
import TextInputWithIcon from "../../../Components/Form/Inputs/TextInputWithIcon";
import { CiUser } from "react-icons/ci";
import { FiLock } from "react-icons/fi";
import { styled } from "styled-components";
import Checkbox from "../../../Components/Form/Inputs/Checkbox";
import BigButton from "../../../Components/Form/Buttons/BigButton";
import {
  getAccountDetails,
  selectUser,
} from "../../../Redux/features/userSlice";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { loginUser } from "../../../Apis/Auth/Account";

const RememberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  font-size: var(--fs-400);
`;

const Login = () => {
  const { username, password, logingUser } = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAccountDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (password && username) {
      loginUser(username, password);
    }
  };

  return (
    <AuthPageLayout authText="Login">
      <FormContainer>
        <TextInputWithIcon
          type="text"
          placeholder="Enter Username"
          icon={<CiUser />}
          value={username}
          name="username"
          handleChange={handleOnChange}
        />
        <TextInputWithIcon
          type="password"
          placeholder="Enter Password"
          icon={<FiLock />}
          value={password}
          name="password"
          handleChange={handleOnChange}
        />
        <RememberContainer>
          <Checkbox />
          <span>Remember me</span>
        </RememberContainer>
        
        <BigButton
          buttonText="Login"
          disabled={false}
          handleClick={handleSubmit}
          loading={logingUser}
        />
      </FormContainer>
      <AuthRedirectionLink to="/reset-password">
        Reset Password
      </AuthRedirectionLink>
    </AuthPageLayout>
  );
};

export default Login;
