import { Dropdown } from 'rsuite';
import styled from 'styled-components';
import { FC } from 'react';

const DropDownContainer = styled(Dropdown)`

`;


const CustomDropdown = ({ ...props }) => (
  <DropDownContainer {...props}>
    {props?.dropdownItems?.map((item:any) => (
        <Dropdown.Item onClick={() => props?.handleDropdownSelect(props?.name, item?.value)}>{item?.label}</Dropdown.Item>
    ))}
  </DropDownContainer>
)

 interface DropdownProps{
    value: any;
    dropdownItems: any;
    title: string;
    handleDropdownSelect: any;
    name: string;
 }

const ReactSuiteDropdown: FC<DropdownProps> = ({value, dropdownItems, title, handleDropdownSelect, name}) => (
  <>
    <CustomDropdown 
    title={dropdownItems?.filter((x: any) => x?.value === value)[0]?.label ===
          "All"
            ? title
            : dropdownItems?.filter((x: any) => x?.value === value)[0]?.label} 
    trigger={['click', 'hover']}
    dropdownItems={dropdownItems}
    handleDropdownSelect={handleDropdownSelect}
    name={name}
    />
  </>
);
export default ReactSuiteDropdown
