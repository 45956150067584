import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { getPaymentsFormDetails, selectPayment } from "../../Redux/features/paymentSlice";
import CustomTable2 from "../CustomTable2";
import { PaymentsCols, paymentRows } from "./utils";

const AllPaymentsTable = () => {
  const { paymentHistory, gettingPaymentHistory } = useAppSelector(selectPayment);
  const dispatch = useAppDispatch();
  const rows = paymentRows(paymentHistory);

  const handlePagination = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageSize", value }));
  };
  
  return (
    <CustomTable2
      data={{ rows, columns: PaymentsCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingPaymentHistory}
      noRecord={paymentHistory?.length === 0}
      page={paymentHistory?.currentPage}
      pageCount={paymentHistory?.totalRecords}
      pageSize={paymentHistory?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
      title="Successful Payments"
    />
  );
};

export default AllPaymentsTable;
