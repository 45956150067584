/* eslint-disable react/require-default-props */
import { FC, useRef } from "react";
import {
  PaginationContainer,
  Table2,
  TableBody,
  TableContainer2,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TableTitle,
} from "./styles";
import Loader from "../Loaders/MoonLoader";
import { IoIosDocument } from "react-icons/io";

interface TableProps {
  data?: {
    columns: any;
    rows: any;
  };
  title?: string;
  loading?: Boolean;
  pageCount?: any;
  currentPage?: any;
  tableId?: string;
  tableRef?: any;
  page?: number;
  pageSize?: number;
  searchOptions?: any;
  noRecordText?: string;
  noRecord?: boolean;
  showFilter?: boolean;
  filter?: any;
  tableRowClickHandler?: any;
  showPagination?: boolean;
  showHeader?: boolean;
  changePagehandler?: any;
  changeLimitHandler?: any;
}

const CustomTable2: FC<TableProps> = ({
  data,
  title,
  loading,
  tableId,
  tableRef,
  searchOptions,
  noRecordText,
  noRecord,
  showFilter,
  filter,
  showPagination,
  showHeader,
  pageCount,
  page,
  pageSize,
  changePagehandler,
  changeLimitHandler,
}) => {
  const tableScroll = useRef<any>();

  return (
    <TableContainer2 ref={tableScroll}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TableTitle>{title}</TableTitle>
        {showFilter && filter}
      </div>
      {searchOptions}
      {!loading ? (
        <div style={{ width: "100%", overflowX: "auto" }}>
          {!noRecord ? (
            <>
              <Table2 id={tableId} ref={tableRef}>
                {showHeader && (
                  <TableHeader>
                    <TableRow>
                      {data?.columns?.map((column: any, index: number) => (
                        <TableHead key={index}>{column?.label}</TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                )}

                <TableBody>
                  {data?.rows?.map((row: any, index: number) => (
                    <TableRow key={index}>
                      {data?.columns?.map((column: any, index: number) => (
                        <TableData key={index}>
                          {row[`${column.field}`]}
                        </TableData>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table2>
              {showPagination && (
                <div style={{ padding: 20 }}>
                  <PaginationContainer
                    prev
                    next
                    //   first
                    //   last
                    ellipsis
                    boundaryLinks
                    maxButtons={2}
                    size="xs"
                    layout={["total", "-", "limit", "pager"]}
                    total={pageCount}
                    limitOptions={[10, 20, 30, 40, 50]}
                    limit={pageSize}
                    activePage={page}
                    onChangePage={(value) => changePagehandler(value)}
                    onChangeLimit={(value) => changeLimitHandler(value)}
                  />
                </div>
              )}
            </>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoIosDocument size={80} />
              <span style={{ fontSize: `25px` }}>No Records</span>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader size={20} color="#f72585" />
        </div>
      )}
    </TableContainer2>
  );
};

export default CustomTable2;
