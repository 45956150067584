import { useState } from "react";
import { styled } from "styled-components";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const CheckboxContainer = styled.div`
  display: flex;
  width: 30px;
  height: 30px;
  align-items: flex-start;

  svg {
    width: 100%;
    height: 100%;
    color: var(--clr-grey-200);
    cursor: pointer;
  }
`;
const Checkbox = () => {
  const [checked, setChecked] = useState(false);

  return (
    <CheckboxContainer>
      {checked ? <MdCheckBox onClick={() => setChecked(false)} /> : <MdCheckBoxOutlineBlank onClick={() => setChecked(true)} />}
    </CheckboxContainer>
  );
};

export default Checkbox;
