import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getPaymentsFormDetails,
  selectPayment,
} from "../../Redux/features/paymentSlice";
import CustomTable2 from "../CustomTable2";
import { PaymentsCols, paymentRows } from "./utils";

const UnSuccessfulPayment = () => {
  const { unsuccessfulPayments, gettingPaymentHistory } = useAppSelector(selectPayment);
  const dispatch = useAppDispatch();
  const rows = paymentRows(unsuccessfulPayments,"UnSuccessful");

  const handlePagination = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageNumber", value }));
  };

  const handlePageLimit = (value: number) => {
    dispatch(getPaymentsFormDetails({ name: "PageSize", value }));
  };

  return (
    <CustomTable2
      data={{ rows, columns: PaymentsCols }}
      showPagination={false}
      showHeader={true}
      loading={gettingPaymentHistory}
      noRecord={unsuccessfulPayments?.length === 0}
      page={unsuccessfulPayments?.currentPage}
      pageCount={unsuccessfulPayments?.totalRecords}
      pageSize={unsuccessfulPayments?.pageSize}
      changeLimitHandler={handlePageLimit}
      changePagehandler={handlePagination}
      title="Unsuccessful Payments"
    />
  );
};

export default UnSuccessfulPayment;
