export const filter = (data: any, name: string, id: string) => {
  const res = [{ label: "All", value: null }].concat(
    data?.map((x: any) => {
      return {
        label: x?.[name],
        value: x?.[id],
      };
    })
  );

  return res;
};
