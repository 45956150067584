import { toast } from "react-toastify";
import api from "../baseUri";
import { store } from "../../Redux/app/store";
import { setRegisteringCourses } from "../../Redux/features/CourseRegSlice";

export const registerCourse = async (semesterId: number|null) => {
  store.dispatch(setRegisteringCourses(true));
  const params = { semesterId };
  await api
    .get("Student/UnderGraduateCourseRegistration", { params })
    .then((res) => {
      const {data} = res
      store.dispatch(setRegisteringCourses(false));
      window.open(`${data}`, '_blank')
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setRegisteringCourses(false));
    });
};
