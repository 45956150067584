import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface HostelState {
  printingHostelCard: boolean;
  savingHostelSelection: boolean;
  gettingActiveBunkByRoomId: boolean;
  activeBunkByRoomId: any;
  gettingActiveBlockRoomByBlockId: boolean;
  activeBlockRoomByBlockId: any;
  gettingHostelActiveBlockByHostelId: boolean;
  hostelActiveBlockByHostelId: any;
  choosingHostel: boolean;
  chosenHostel: any;
  allocatedHostel: any;

  semesterId: number | null,
  hostelId: number | null,
  blockId: number | null,
  roomId: number | null,
  bunkId: number | null,
}

const initialState: HostelState = {
printingHostelCard: false,
savingHostelSelection: false,
gettingActiveBunkByRoomId: false,
activeBunkByRoomId: null,
gettingActiveBlockRoomByBlockId: false,
activeBlockRoomByBlockId: null,
gettingHostelActiveBlockByHostelId: false,
hostelActiveBlockByHostelId: null,
choosingHostel: false,
chosenHostel: null,
allocatedHostel: null,


semesterId: null,
hostelId: null,
blockId: null,
roomId: null,
bunkId: null,
};

export const HostelSlice = createSlice({
  name: "Hostel",
  initialState,
  reducers: {
    setPrintingHostelCard: (state, action) => {
      state.printingHostelCard = action.payload;
    },
    setSavingHostelSelection: (state, action) => {
      state.savingHostelSelection = action.payload;
    },
    setGettingActiveBunkByRoomId: (state, action) => {
      state.gettingActiveBunkByRoomId = action.payload;
    },
    setActiveBunkByRoomId: (state, action) => {
      state.activeBunkByRoomId = action.payload;
    },
    setGettingActiveBlockRoomByBlockId: (state, action) => {
      state.gettingActiveBlockRoomByBlockId = action.payload;
    },
    setActiveBlockRoomByBlockId: (state, action) => {
      state.activeBlockRoomByBlockId = action.payload;
    },
    setGettingHostelActiveBlockByHostelId: (state, action) => {
      state.gettingHostelActiveBlockByHostelId = action.payload;
    },
    setHostelActiveBlockByHostelId: (state, action) => {
      state.hostelActiveBlockByHostelId = action.payload;
    },
    setChoosingHostel: (state, action) => {
      state.choosingHostel = action.payload;
    },
    setChosenHostel: (state, action) => {
      state.chosenHostel = action.payload;
    },
    setAllocatedHostel: (state, action) => {
      state.allocatedHostel = action.payload;
    },
    getHostelsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setActiveBlockRoomByBlockId,
  setActiveBunkByRoomId,
  setChoosingHostel,
  setChosenHostel,
  setGettingActiveBlockRoomByBlockId,
  setGettingActiveBunkByRoomId,
  setGettingHostelActiveBlockByHostelId,
  setHostelActiveBlockByHostelId,
  setPrintingHostelCard,
  setSavingHostelSelection,
  getHostelsFormDetails,
  setAllocatedHostel
} = HostelSlice.actions;
export const selectHostel = (state: RootState) => state.hostel;

export default HostelSlice.reducer;
