import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface PaymentState {
  gettingPaymentHistory: boolean;
  paymentHistory: any;
  unsuccessfulPayments: any;
  generatingInvoice : boolean;
  generatedInvoice: any;
  gettingPaymentReceipt: boolean;
  paymentReceipt: any;

  sessionId: number | null;
  semesterId: number | null;
  feeScheduleId: number | null;

  paymentById: number | null;
}

const initialState: PaymentState = {
  gettingPaymentHistory: false,
  paymentHistory: [],
  unsuccessfulPayments: [],
  sessionId: null,
  semesterId: null,
  feeScheduleId: null,
  generatingInvoice : false,
  generatedInvoice: null,
  gettingPaymentReceipt: false,
  paymentReceipt:null,

  paymentById: null,
};

export const PaymentSlice = createSlice({
  name: "Payment",
  initialState,
  reducers: {
    setGettingPaymentHistory: (state, action) => {
      state.gettingPaymentHistory = action.payload;
    },
    setPaymentHistory: (state, action) => {
      state.paymentHistory = action.payload;
    },
    setGettingPaymentReceipt: (state, action) => {
      state.gettingPaymentReceipt = action.payload;
    },
    setPaymentReceipt: (state, action) => {
      state.paymentReceipt = action.payload;
    },
    setUnSuccessfulPayments: (state, action) => {
      state.unsuccessfulPayments = action.payload;
    },
    setGeneratingInvoice: (state, action) => {
      state.generatingInvoice = action.payload;
    },
    setGeneratedInvoice: (state, action) => {
      state.generatedInvoice = action.payload;
    },
    getPaymentsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setGettingPaymentHistory,
  setPaymentHistory,
  getPaymentsFormDetails,
  setUnSuccessfulPayments,
  setGeneratingInvoice,
  setGettingPaymentReceipt,
  setPaymentReceipt,
  setGeneratedInvoice
} = PaymentSlice.actions;
export const selectPayment = (state: RootState) => state.payment;

export default PaymentSlice.reducer;
