import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../Redux/app/hooks'
import { selectUtil } from '../../../Redux/features/utilsSlice'
import { filter } from '../util'
import CustomRegularDropdown from '../../../Components/Form/Dropdowns/CustomRegularDropdown'
import { getPaymentsFormDetails, selectPayment } from '../../../Redux/features/paymentSlice'

const PaymentHistoryFilter = () => {
    const {sessions} = useAppSelector(selectUtil)
    const {sessionId} = useAppSelector(selectPayment)
    const dispatch = useAppDispatch()

  const sessionFilter = filter(sessions, "sessionName", "sessionId");

    
  const handleSelect = (name: string, value: string | number) => {
    dispatch(getPaymentsFormDetails({ name, value }));
  };


  return (
    <>
     <CustomRegularDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="sessionId"
        value={sessionId}
        />
    </>
  )
}

export default PaymentHistoryFilter