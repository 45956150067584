import { HeaderContainer, HeaderRight, HeaderTop } from "./style";
import { RiMenu3Fill } from "react-icons/ri";
import { useAppDispatch } from "../../../Redux/app/hooks";
import { toggleSidebar } from "../../../Redux/features/navSlice";
import UserActionDropdown from "../../Form/Dropdowns/UserActionDropdown";

const Header = () => {
  const dispatch = useAppDispatch();

  return (
    <HeaderContainer>
      <HeaderTop>
        <img src="/assets/images/PNG/logo.png" alt="" />
        <HeaderRight className="">
          <UserActionDropdown />
          <RiMenu3Fill
            onClick={() => dispatch(toggleSidebar())}
            className="menu"
          />
        </HeaderRight>
      </HeaderTop>
    </HeaderContainer>
  );
};

export default Header;
