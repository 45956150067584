import { Link } from "react-router-dom";
import styled from "styled-components";

export const InnerNavContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
//   position: sticky;
//   top: 0;
`;

export const Tab = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  &:hover {
    color: #000;
    text-decoration: none;
  }
  &:focus {
    color: #000;
    text-decoration: none;
  }

  &.active {
    border-bottom: 2px solid #7209b7;
  }
`;
