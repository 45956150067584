import { FC } from "react";
import {
  AuthBanner,
  AuthPageContainer,
  AuthText,
  CompanyLogo,
  FormSection,
  LeftContent,
  RightContent,
} from "./styles/AuthPageLayout";

interface AuthPageLayoutProps {
  children: any;
  authText: string;
}
const AuthPageLayout: FC<AuthPageLayoutProps> = ({ children, authText }) => {
  return (
    <AuthPageContainer>
      <LeftContent>
        <FormSection>
          <CompanyLogo src="/assets/images/PNG/logo.png" alt="logo" />
          <AuthText>{authText}</AuthText>
          {children}
        </FormSection>
      </LeftContent>
      <RightContent>
        <AuthBanner>
          <img src="/assets/images/PNG/gouni-banner-2.jfif" alt="banner" />
        </AuthBanner>
      </RightContent>
    </AuthPageContainer>
  );
};

export default AuthPageLayout;
