import React from "react";
import {
  Form,
  FormCardContainer,
  FormSectionContainer,
  PageTitle,
} from "../style";
import InnerNavTabs from "../../../Components/Nav/InnerNavTabs";
import { TabList } from "./utils";
import TextCenteredInput from "../../../Components/Form/Inputs/TextCenteredInput";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getAccountDetails,
  selectUser,
} from "../../../Redux/features/userSlice";
import { resetPassword } from "../../../Apis/Auth/Account";
import BigButton from "../../../Components/Form/Buttons/BigButton";

const ChangePassword = () => {
  const { password, newPassword, resettingPassword, currentUser } =
    useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getAccountDetails({ name, value }));
  };

  const handleSubmit = () => {
    if (password && newPassword) {
      resetPassword(currentUser?.username, password, newPassword);
    }
  };

  return (
    <>
      <PageTitle>Account</PageTitle>
      <InnerNavTabs tabList={TabList} />
      <FormSectionContainer>
        <FormCardContainer>
          <Form>
            <TextCenteredInput
              alignText="left"
              placeholder="Username"
              name="username"
              onChangeHandler={handleOnChange}
              value={currentUser?.username}
            />
            <TextCenteredInput
              placeholder="Current Password"
              alignText="left"
              name="password"
              onChangeHandler={handleOnChange}
            />
            <TextCenteredInput
              placeholder="New Password"
              alignText="left"
              name="newPassword"
              onChangeHandler={handleOnChange}
            />
          </Form>
          <BigButton
            buttonText="Reset"
            disabled={false}
            handleClick={handleSubmit}
            loading={resettingPassword}
          />
        </FormCardContainer>
      </FormSectionContainer>
    </>
  );
};

export default ChangePassword;
