import React from 'react'
import { selectUtil } from '../../../Redux/features/utilsSlice';
import { useAppDispatch, useAppSelector } from '../../../Redux/app/hooks';
import { filter } from '../util';
import { PageTitle } from '../style';
import UnderlinedSelector from '../../../Components/Form/Dropdowns/UnderlinedSelector';
import SmallButton from '../../../Components/Form/Buttons/SmallButton';
import { getResultsFormDetails, selectResult } from '../../../Redux/features/resultSlice';
import { checkResult } from '../../../Apis/Private/Result';
import { toast } from 'react-toastify';

const Result = () => {
  const { sessions } =
  useAppSelector(selectUtil);
  const { sessionId, checkingResult } =
  useAppSelector(selectResult);
  const dispatch = useAppDispatch()


  const sessionFilter = filter(sessions, "sessionName", "sessionId");

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getResultsFormDetails({ name, value }));
  };

  const handleCheckResult = (e:any) => {
      e.preventDefault()
      if(sessionId){
        checkResult(sessionId)
      }
      else{
        toast.error("Please put in the session")
      }
  }

  
  return (
    <>
    <PageTitle>Check Result</PageTitle>
    <div className="flex-centered-items flex-wrap">
      <UnderlinedSelector
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="sessionId"
        value={sessionId}
      />
      </div>
      <SmallButton buttonText='Check' handleClick={handleCheckResult} loading={checkingResult}/>
    </>
  )
}

export default Result