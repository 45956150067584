import React from "react";
import { Routes, Route } from "react-router-dom";
import Login0 from "../Pages/AuthPages/Login";
import ForgotPassword from "../Pages/AuthPages/ForgotPassword";
// import SignUp from "../Pages/AuthPages/SignUp";

const AuthRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<SignUp />} /> */}
      <Route path="/" element={<Login0 />} />
      <Route path="/reset-password" element={<ForgotPassword />} />
    </Routes>
  );
};

export default AuthRoutes;
