import { toast } from "react-toastify";
import api from "../baseUri";
import {
  setActiveSession,
  setAdmissionTypes,
  setBankList,
  setDepartments,
  setFaculties,
  setFeeSchedule,
  setGettingActiveSession,
  setGettingAdmissionTypes,
  setGettingBankList,
  setGettingFaculties,
  setGettingFeeSchedule,
  setGettingHostelBlock,
  setGettingHostels,
  setGettingPaymentMean,
  setGettingSemesters,
  setGettingSessions,
  setHostelBlock,
  setHostels,
  setPaymentMean,
  setSemesters,
  setSessions,
} from "../../Redux/features/utilsSlice";
import { store } from "../../Redux/app/store";

export const getSessions = async () => {
  store.dispatch(setGettingSessions(true));
  await api
    .get("/Utill/GetSession")
    .then((res) => {
      const { data } = res;
      store.dispatch(setSessions(data));
      store.dispatch(setGettingSessions(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingSessions(false));
    });
};

export const getSemester = async () => {
  store.dispatch(setGettingSemesters(true));
  await api
    .get("/Utill/GetSemester")
    .then((res) => {
      const { data } = res;
      store.dispatch(setSemesters(data));
      store.dispatch(setGettingSemesters(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingSemesters(false));
    });
};

export const getFeeschedule = async () => {
  store.dispatch(setGettingFeeSchedule(true));
  await api
    .get("/Utill/GetFeeschedule")
    .then((res) => {
      const { data } = res;
      store.dispatch(setFeeSchedule(data));
      store.dispatch(setGettingFeeSchedule(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingFeeSchedule(false));
    });
};

export const getPaymentMean = async () => {
  store.dispatch(setGettingPaymentMean(true));
  await api
    .get("/Utill/GetPaymentMean")
    .then((res) => {
      const { data } = res;
      store.dispatch(setPaymentMean(data));
      store.dispatch(setGettingPaymentMean(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingPaymentMean(false));
    });
};

export const getHostel = async () => {
  store.dispatch(setGettingHostels(true));

  await api
    .get("/Utill/GetHostel")
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostels(data));
      store.dispatch(setGettingHostels(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHostels(false));
    });
};

export const getHostelBlock = async (hostelId?: number) => {
  store.dispatch(setGettingHostelBlock(true));
  const params: any = {
    hostelId,
  };
  Object.keys(params)?.forEach((key) => {
    if (params[key] === null) {
      delete params[key];
    }
  });
  await api
    .get("/Utill/GetHostelBlock", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setHostelBlock(data));
      store.dispatch(setGettingHostelBlock(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingHostelBlock(false));
    });
};

export const getFaculties = async () => {
  store.dispatch(setGettingFaculties(true));
  await api
    .get("/Utill/GetFaculties")
    .then((res) => {
      const { data } = res;
      store.dispatch(setFaculties(data));
      store.dispatch(setGettingFaculties(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingFaculties(false));
    });
};

export const getDepartments = async () => {
  await api
    .get("/Utill/Getdepartments")
    .then((res) => {
      const { data } = res;
      store.dispatch(setDepartments(data));
      store.dispatch(setGettingFaculties(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingFaculties(false));
    });
};

export const getActiveSession = async () => {
  store.dispatch(setGettingActiveSession(true));

  await api
    .get("/Utill/GetActiveSession")
    .then((res) => {
      const { data } = res;
      store.dispatch(setActiveSession(data));
      store.dispatch(setGettingActiveSession(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingActiveSession(false));
    });
};

export const getBankList = async () => {
  store.dispatch(setGettingBankList(true));
  await api
    .get("/Utill/GetBanks")
    .then((res) => {
      const { data } = res;
      store.dispatch(setBankList(data));
      store.dispatch(setGettingBankList(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingBankList(false));
    });
};

export const getAdmissionTypes = async () => {
  store.dispatch(setGettingAdmissionTypes(true));
  await api
    .get("/Utill/GetAdmissionType")
    .then((res) => {
      const { data } = res;
      store.dispatch(setAdmissionTypes(data));
      store.dispatch(setGettingAdmissionTypes(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingAdmissionTypes(false));
    });
};
