import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import navReducer from "../features/navSlice";
import userReducer from "../features/userSlice";
import reportReducer from "../features/admissionSlice";
import UtilsReducer from "../features/utilsSlice";
import AdmissionReducer from "../features/admissionSlice";
import CourseRegReducer from "../features/CourseRegSlice";
import ResultReducer from "../features/resultSlice";
import ClearanceReducer from "../features/clearanceSlice";
import PaymentReducer from "../features/paymentSlice";
import HostelReducer from "../features/hostelSlice";

export const store = configureStore({
  reducer: {
    nav: navReducer,
    user: userReducer,
    report: reportReducer,
    utils: UtilsReducer,
    admission: AdmissionReducer,
    courseReg: CourseRegReducer,
    result: ResultReducer,
    clearance: ClearanceReducer,
    payment: PaymentReducer,
    hostel: HostelReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
