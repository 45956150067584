import React, { FC } from "react";
import { styled } from "styled-components";
import { media } from "../../Screens";

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  background: var(--clr-white-100);
  border-radius: 10px;
  padding: 10px;
  gap: 20px;
  justify-content: center;
  align-items: center;

  ${media.tablet} {
    padding: 30px;
  }
`;

interface CardProps {
  children: any;
}

const LongWhiteCard: FC<CardProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default LongWhiteCard;
