import React, { FC } from "react";
import styled from "styled-components";
import Loader from "../../../Loaders/MoonLoader";



const Conatainer = styled.button<SmallButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  height: 35px;
  border: none;
  outline: none;
  background: var(--clr-primary-900);
  box-shadow: 0px 4px 20px 0px rgba(90, 2, 14, 0.15);
  padding: 10px 20px;
  color: var(--clr-white-100);
  border-radius: 3px;
  text-transform: ${(props) =>
    props?.textTransform ? props?.textTransform : `uppercase`};
  cursor: pointer;
  gap: 10px;
  opacity: ${(props) => (props?.disabled ? `0.25` : `1`)};
  svg {
    font-size: 18px;
  }
  transition: all 0.25s ease-in-out;
  box-shadow: var(--box-shadow-2);
  border-radius: 50px;


  &:hover{
  background: var( --clr-primary-100);
  box-shadow: var(--box-shadow);
}
`;

interface SmallButtonProps {
  buttonText?: string;
  textTransform?: string;
  handleClick?: any;
  disabled?: boolean;
  leftIcon?: any;
  rightIcon?: any;
  loading?: any;
  buttonType?: any;
}

const SmallButton: FC<SmallButtonProps> = ({
  buttonText,
  disabled,
  textTransform,
  handleClick,
  leftIcon,
  rightIcon,
  loading,
  buttonType,
}) => {
  return (
    <Conatainer
      disabled={disabled}
      textTransform={textTransform}
      onClick={(e) => {
        e.preventDefault();
        handleClick && handleClick(e);
      }}
      type={buttonType}
    >
      {loading ? (
        <Loader color="#fff" size="12" />
      ) : (
        <>
          {leftIcon && leftIcon}
          {buttonText}
          {rightIcon && rightIcon}
        </>
      )}
    </Conatainer>
  );
};

export default SmallButton;
