import { toast } from "react-toastify";
import api from "../baseUri";
import {
  setClearanceForm,
  setGettingClearanceForm,
  setPostingClearanceForm,
} from "../../Redux/features/clearanceSlice";
import { store } from "../../Redux/app/store";

export const getClearanceForm = async (
  sessionId: number,
  semesterId: number
) => {
  store.dispatch(setGettingClearanceForm(true));
  const params = { sessionId, semesterId };
  await api
    .get("Student/clearanceForm", { params })
    .then(() => {
      store.dispatch(setGettingClearanceForm(false));
      toast.success("Successful")
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingClearanceForm(false));
    });
};

export const postClearanceForm = async (
  sessionId: number,
  semesterId: number
) => {
  store.dispatch(setPostingClearanceForm(true));
  const params = { sessionId, semesterId };
  await api
    .get("Student/PrintClearanceForm", { params })
    .then((res) => {
      const {data} = res
      store.dispatch(setClearanceForm(data))
      store.dispatch(setPostingClearanceForm(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setPostingClearanceForm(false));
    });
};



export const printClearanceForm = async (
  sessionId: number,
  semesterId: number
) => {
  store.dispatch(setGettingClearanceForm(true));
  const params = { sessionId, semesterId };
  await api
    .get("Student/PrintClearanceForm", { params })
    .then((res) => {
      const { data } = res;
      store.dispatch(setClearanceForm(data));
      store.dispatch(setGettingClearanceForm(false));
    })
    .catch((err: any) => {
      if (err?.response) toast.error(err?.response?.data);
      if (err?.message === "Network Error") toast.error(err?.message);
      store.dispatch(setGettingClearanceForm(false));
    });
};