import React from 'react'
import { PageTitle } from '../style'
import UnderlinedSelector from '../../../Components/Form/Dropdowns/UnderlinedSelector'
import { useAppDispatch, useAppSelector } from '../../../Redux/app/hooks';
import { selectUtil } from '../../../Redux/features/utilsSlice';
import { filter } from '../util';
import { Description } from '../Dashboard/style';
import SmallButton from '../../../Components/Form/Buttons/SmallButton';
import { getCourseRegFormDetails, selectCourseReg } from '../../../Redux/features/CourseRegSlice';
import { registerCourse } from '../../../Apis/Private/courseReg';

const CourseReb = () => {
    const { semesters  } = useAppSelector(selectUtil);
    const { semesterId, registeringCourses  } = useAppSelector(selectCourseReg);
    const dispatch = useAppDispatch()



    const semesterFilter = filter(semesters, "semesterName", "semesterId");
  
    const handleSelect = (name: string, value: string | number) => {
      dispatch(getCourseRegFormDetails({ name, value }));
    };

  return (
    <>
    <PageTitle>Course Registration</PageTitle>
    <Description>Select the right semester to register</Description>
    {/* <Description><BsInfoCircleFill color='gold' size={20}/> Note: Don't play here </Description> */}
    <div className="flex-centered-items flex-wrap">
      <UnderlinedSelector
        dropdownItems={semesterFilter}
        title={`Semester`}
        handleDropdownSelect={handleSelect}
        name="semesterId"
        value={semesterId}
      />
    </div>
    <SmallButton buttonText='Register' handleClick={() => registerCourse(semesterId)} loading={registeringCourses}/>
    </>
    
  )
}

export default CourseReb