import styled from "styled-components";
import { media } from "../../Screens";
import { Link } from "react-router-dom";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  gap: 1.25rem;

  ${media.tablet} {
    width: 70%;
  }
`;

export const AuthRedirectionLink = styled(Link)`
  color: var(--clr-primary-900);
  font-size: var(--fs-400);
  font-weight: var(--fw-bold);
  line-height: normal;
  // margin-top: var(--size-700);
  text-decoration: none;
  text-transform: capitalize;
  cursor: pointer;
`;
