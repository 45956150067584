import { Routes, Route } from "react-router-dom";
import {
  MainBody,
  MainBodyContainer,
  RouteContainer,
} from "../Pages/PrivatePages/style";
import Header from "../Components/Nav/Header";
import Sidebar from "../Components/Nav/Sidebar";
import { useEffect } from "react";
import {
  getActiveSession,
  getAdmissionTypes,
  getBankList,
  getDepartments,
  getFaculties,
  getFeeschedule,
  getHostel,
  getHostelBlock,
  getPaymentMean,
  getSemester,
  getSessions,
} from "../Apis/Private/Utils";
import AdmissionLetter from "../Pages/PrivatePages/Admissions/AdmissionLetter";
import Clearance from "../Pages/PrivatePages/Clearance";
import CourseReg from "../Pages/PrivatePages/CourseReg";
import Result from "../Pages/PrivatePages/Results";
import Dashboard from "../Pages/PrivatePages/Dashboard";
import Account from "../Pages/PrivatePages/Account";
import ChangePassword from "../Pages/PrivatePages/Account/ChangePassword";
import AcknowledgementSlip from "../Pages/PrivatePages/Admissions/AcknowledgeSlip";
import PaymentHistory from "../Pages/PrivatePages/Payments";
import GenerateInvoice from "../Pages/PrivatePages/Payments/GenerateInvoice";
import Hostel from "../Pages/PrivatePages/Hostel";
import PaymentReceipt from './../Pages/PrivatePages/Payments/PaymentReceipt';
import InvoicePage from "../Pages/PrivatePages/Payments/InvoicePage";

const PrivateRoutes = () => {
  useEffect(() => {
    getSessions();
    getSemester();
    getFeeschedule();
    getPaymentMean();
    getHostel();
    getFaculties();
    getDepartments();
    getActiveSession();
    getHostelBlock();
    getBankList();
    getAdmissionTypes()
  }, []);

  return (
    <RouteContainer>
      <Header />
      <MainBodyContainer>
        <Sidebar />
        <MainBody>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            {/* <Route path="/admission/admission-letter" element={<AdmissionLetter />} />
            <Route path="/admission/acknowledgement-slip" element={<AcknowledgementSlip />} /> */}
            <Route path="/clearance" element={<Clearance />} />
            <Route path="/course-registration" element={<CourseReg />} />
            <Route path="/result" element={<Result />} />
            <Route path="/account" element={<Account />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/payment/payment-history" element={<PaymentHistory />} />
            <Route path="/payment/view-payment-receipt/:id" element={<PaymentReceipt />} />
            <Route path="/payment/generate-invoice" element={<GenerateInvoice />} />
            <Route path="/payment/view-generated-invoice" element={<InvoicePage />} />
            <Route path="/hostel" element={<Hostel />} />
          </Routes>
        </MainBody>
      </MainBodyContainer>
    </RouteContainer>
  );
};

export default PrivateRoutes;
