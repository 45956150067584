
import styled from 'styled-components'
import { media } from '../../../Screens'

export const WelcomeMessage = styled.div`
display: flex;
align-items: center;
width: 100%;
font-size: 18px;

${media.tablet}{
    font-size: 30px;
}

`

export const DirectionContainer = styled.div`
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
gap: 10px;
;`

export const DirectText = styled.p`
font-size: 18px;
color: var(--clr-black)
`

export const Description = styled.p`
display: flex;
align-items: center;
font-size: 14px,
color: (--clr-grey-300);
gap: 10px;
`