import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { PiArrowRightThin, PiCaretLeft } from "react-icons/pi";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { useParams } from "react-router-dom";
import {  useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import moment from "moment";
import { getPaymentReceipt } from "../../../Apis/Private/Payment";
import { selectUtil } from "../../../Redux/features/utilsSlice";
import { ReceiptBody, ReceiptContainer, ReceiptHeader, SchoolName, SchoolPhone, SectionTitle, Table, TableHead, TableData, TableRow } from "./styles";
import ReactPrint from "../../ReactPrint";
import { Description, DirectionContainer } from "../Dashboard/style";
import Loader from "../../../Loaders/MoonLoader";
import { MdOutlineError } from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import { NavigationLink } from "../../../Tables/styles";

const PaymentReceipt = () => {
  const { id } = useParams();
  const { paymentReceipt, gettingPaymentReceipt } = useAppSelector(selectPayment);
  const {paymentMean} = useAppSelector(selectUtil);

  // const {}
  useEffect(() => {
    getPaymentReceipt(Number(id));
  }, [id]);

  return (
    <>
    <PageTitle>Payment Receipt</PageTitle>
    <NavigationLink to={`/payment/payment-history`} className="flex-centered-items">
        <PiCaretLeft />
        <span>Back</span>
      </NavigationLink>
    <LongTransparentCard>
      <LongWhiteCard>
        {gettingPaymentReceipt ? <Loader size={20} color="#f72585" /> :  
        <>
        {paymentReceipt ? <ReactPrint>
          <ReceiptContainer>
          <ReceiptHeader>
            <img src="/assets/images/PNG/logo.png" alt="logo"/>
            <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
            <SchoolPhone>{paymentReceipt?.feeSchedule.feeScheduleName} receipt</SchoolPhone>
          </ReceiptHeader>
          <img src={`https://erp.gouni.edu.ng/` + paymentReceipt?.person?.passportUrl?.substring(1,paymentReceipt?.payment?.person?.passportUrl?.length)} alt="profile" width={100} height={100} style={{borderRadius: "10px"}}/>
          <ReceiptBody>
            <ItemsCenterJustifyBtw className="align-start">
              <div style={{width: "100%"}}>
              <SectionTitle>From</SectionTitle>
              <p>{paymentReceipt?.person?.firstName + paymentReceipt?.person?.lastName + paymentReceipt?.person?.otherName}</p>
              <p>{paymentReceipt?.person?.emailAddress}</p>
              <p>{paymentReceipt?.person?.contactAddress}</p>
              </div>
              <PiArrowRightThin size={30} width={100}/>
              <div style={{width: "100%"}}>
              <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>To</SectionTitle><span>Godfrey Okeye Univeristy</span></div>
              <div >
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Payment type: </SectionTitle> <span>{paymentReceipt?.feeSchedule.feeScheduleName}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Order No/Payment ID: </SectionTitle> <span>{+paymentReceipt?.paymentId}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Payee ID: </SectionTitle><span>{paymentReceipt?.invoiceNumber}</span></div>
                <div style={{display:"flex", alignItems: "center", gap:"10px"}}><SectionTitle>Invoice Date: </SectionTitle><span>{moment(paymentReceipt?.transactionDate).format("DD MMM YYYY")}</span></div>
              </div>
              </div>
            </ItemsCenterJustifyBtw>
            <Table>
              <TableRow>
                <TableHead>Item</TableHead>
                <TableHead>Quantity</TableHead>
                <TableHead>Unit Price(#)</TableHead>
                <TableHead>Total Price(#)</TableHead>
              </TableRow>
              <TableRow>
                <TableData>{paymentReceipt?.feeSchedule.feeScheduleName}</TableData>
                <TableData>1</TableData>
                <TableData>15000</TableData>
                <TableData>15000</TableData>
              </TableRow>
            </Table>
            <Table>
            <TableRow>
                <TableHead>SubTotal: </TableHead>
                <TableData>15000</TableData>
              </TableRow>
            <TableRow>
                <TableHead>VAT: </TableHead>
                <TableData>0.00</TableData>
              </TableRow>
            <TableRow>
                <TableHead>Total: </TableHead>
                <TableData>15000</TableData>
              </TableRow>
            </Table>
          </ReceiptBody>
          <DirectionContainer>
                {/* <Description><BsInfoCircleFill color='red' size={20}/>Note: you will be charged #500 for the services</Description> */}
            </DirectionContainer>
          </ReceiptContainer>
        </ReactPrint>
        :
        <Description><MdOutlineError color='red' size={20}/> Sorry, you have not been given admission yet. <br /> Please check back later</Description>
        }
      </>}
      </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default PaymentReceipt;
