import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface ClearanceState {
  postingClearanceForm: boolean;
  gettingClearanceForm: boolean;
  clearanceForm: any;

  semesterId: number | null;
  sessionId: number | null;
}

const initialState: ClearanceState = {
  postingClearanceForm: false,
  gettingClearanceForm: false,
  clearanceForm: null,

  
  semesterId: null,
  sessionId: null,
};

export const ClearanceSlice = createSlice({
  name: "Clearance",
  initialState,
  reducers: {
    setGettingClearanceForm: (state, action) => {
      state.gettingClearanceForm = action.payload;
    },
    setClearanceForm: (state, action) => {
      state.clearanceForm = action.payload;
    },
    setPostingClearanceForm: (state, action) => {
      state.postingClearanceForm = action.payload;
    },
    getClearancesFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setClearanceForm,
  setGettingClearanceForm,
  setPostingClearanceForm,
  getClearancesFormDetails,
} = ClearanceSlice.actions;
export const selectClearance = (state: RootState) => state.clearance;

export default ClearanceSlice.reducer;
