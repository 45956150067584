import styled from "styled-components";
import { media } from "../../../Screens";

export const ReceiptContainer = styled.div`
display: flex;
align-items: flex-start;
flex-direction: column;
justify-content: flex-start;
gap: 20px;
padding: 30px;
border: 2px solid orange;
background-color: #f4f1ec;
width: 100%;
min-height: 700px;
`

export const ReceiptHeader = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 15px;
border: 1px solid orange;
// background: var(--clr-dark-blue);
padding: 20px;
border-bottom-left-radius: 30%;
border-top-right-radius: 30%;
transition: all 0.3s ease-in-out;
box-shadow: 1px 2px 10px rgba(0,0,0, 0.5);

&:hover{
    border-radius: 0;
}

img{
    width: 50px;

    ${media.tablet}{
        width: 100px;
    }
}
`

export const SchoolName = styled.p`
color: var(--clr-black);
font-weight: var( --fw-bol);
font-size: 10px;
text-transform: uppercase;

${media.tablet}{
    font-size: 16px;
}
`

export const SchoolPhone = styled.p`
color: var(--clr-black);
font-size: 10px;
text-transform: uppercase;

${media.tablet}{
    font-size: 18px;
}
`

export const ReceiptBody = styled.div`
    display: block;
    width: 100%;
    padding: 30px 0;
`

export const SectionTitle = styled.div`
    display: block;
    font-weight: 600;
    color: #000;
    font-size: 16px;
`

export const Table = styled.table`
width: 100%;
margin: 30px 0;
border: 1px solid #000;
`
export const TableRow = styled.tr`
border-bottom: 1px solid #000;
padding: 10px;

`
export const TableHead = styled.th`
text-align: left;
padding: 10px;
`
export const TableData = styled.td`
text-align: left;
padding: 10px;
`