import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface ResultState {
  checkingResult: boolean;
  result: any;

  sessionId: number | null;

}

const initialState: ResultState = {
  checkingResult: false,
  result: null,

  sessionId: null,
};

export const ResultSlice = createSlice({
  name: "Result",
  initialState,
  reducers: {
    setCheckingResult: (state, action) => {
      state.checkingResult = action.payload;
    },
    setResult: (state, action) => {
      state.result = action.payload;
    },
    getResultsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const { setCheckingResult, setResult,getResultsFormDetails } = ResultSlice.actions;
export const selectResult = (state: RootState) => state.result;

export default ResultSlice.reducer;
