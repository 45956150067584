import { styled } from "styled-components";
import { Nav } from "rsuite";
import { media } from "../../../Screens";

interface SidbarStyleProps {
  showSidebar: boolean;
}

export const SidebarContainer = styled.div<SidbarStyleProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  left: ${(props) => (props.showSidebar ? `0` : `-100%`)};
  width: ${(props) => (props.showSidebar ? `50%` : `0`)};
  align-items: flex-start;
  position: absolute;
  top: 0;
  background-color: var(--clr-primary-900);
  // backdrop-filter: blur(25px);
    box-shadow: 0px 4px 4px 4px rgba(0, 0, 0, 0.2);
  transition: width 0.5s ease-in-out;
  padding-left: 20px;
  z-index: 99;

  ${media.tablet} {
    width: ${(props) => (props.showSidebar ? `30%` : `0`)};
    left: 0;
    position: relative;

  }

  ${media.tablet} {
    width: 15%;
    position: relative;
    padding-left: 0;
  }

  .rs-dropdown .rs-dropdown-toggle {
    padding-left: 0;
  }

  .rs-sidenav-collapse-in .rs-dropdown-item,
  .rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
    padding-left: 0;

  }

  .rs-sidenav-nav .rs-sidenav-item,
  .rs-sidenav-nav .rs-dropdown-toggle,
  .rs-sidenav-nav .rs-dropdown-item,
  .rs-sidenav-nav .rs-dropdown-item-toggle .rs-sidenav {
    padding-left: 0;
    background-color: transparent;
    color: var(--clr-white-100);
    

    &:hover{
      background-color: var(--clr-white-100);
      color: var(--clr-primary-900); 
      border-radius: 8px;
      padding: 12px;
      transition: all 0.25s ease-in;
  
      
      a {
        color: var(--clr-primary-900);
      }
  
      svg {
        color: var(--clr-primary-900);
      }
    }
  }
 .rs-sidenav-default.rs-sidenav-collapse-in .rs-sidenav-item.rs-sidenav-item-active{
    background-color: var(--clr-white-100);
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
    color: var(--clr-primary-900);
    padding: 12px;
    


    a{
    color: var(--clr-primary-900);
    }

    svg{
    color: var(--clr-primary-900);
    }
  }

    .rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu-item-focus,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item-active {
    background-color: var(--clr-white-100);
    border-radius: 8px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    color: var(--clr-primary-900);
    padding: 12px;

    a {
      color: var(--clr-primary-900);
    }

    svg {
      color: var(--clr-primary-900);
    }
  }

    ${media.tablet} {
      padding-left: 25px;
    }

    .rs-sidenav-nav > .rs-dropdown .rs-dropdown-menu-item:hover,
  .rs-sidenav-nav > .rs-dropdown .rs-dropdown-item:hover{
    background-color: var(--clr-white-100);
    color: var(--clr-primary-900); 
    border-radius: 8px;
    padding: 12px;
    transition: all 0.25s ease-in;

    
    a {
      color: var(--clr-primary-900);
    }

    svg {
      color: var(--clr-primary-900);
    }
  }
  }
`;

export const NavItem = styled(Nav.Item)`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: transparent;
  font-size: 14px;
  margin-right: 10px;
  color: var(--clr-white-100);
  

  svg {
    font-size: 23px;
    font-weight: var(--fw-vey-bold);
    color: var(--clr-white-100);
  }

  a {
    color: var(--clr-white-100);
    text-transform: capitalize;
    text-decoration: none;
  }
`;

export const NavMenu = styled(Nav.Menu)`
display: flex;
align-items: center;
  background: none;
  padding-left: none;
`;

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px 15px 0px;
  gap: 22px;

  span {
    font-size: var(--fs-500);
    font-weight: var(fw-regular);
    line-height: normal;
    color: var(--clr-black);
  }

  svg {
    font-size: var(--fs-700);
    color: var(--clr-black);
  }
`;

export const NavContainer = styled(Nav)`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
  padding-right: 20px;
`;
